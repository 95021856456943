@import "src/styles/colors";

.ant-modal-header,
.ant-modal-footer {
  padding: 16px;
  border: none;
}

.ant-modal-header {
  display: flex;
  justify-content: center;
  @apply bg-primary/30;
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
}

.ant-modal-title {
  color: $black!important;
  font-size: 16px!important;
  font-weight: 900!important;
  letter-spacing: .6px!important;
  line-height: 16px!important;
}

.ant-modal-body {
  padding: 0!important;
  color: $bright-gray;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-modal {
  width: 100%!important;
  max-width: 698px!important;
}
