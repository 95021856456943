@import "src/styles/colors";

.ant-calendar-range {
  width: 100%!important;
  max-width: 463px!important;
}

.booking-modal {
  .ant-divider-horizontal {
    margin: 0!important;
  }

  .body {
    width: 100%;
    max-width: 463px;
    margin: 0 auto;
    padding: 12px 0;

    .ant-divider-horizontal {
      margin: 24px 0!important;
    }

    .ant-radio-group {
      width: 100%!important;

      .ant-radio-wrapper {
        margin-right: 0!important;
        margin-bottom: 12px;
        width: 100%;

        span.ant-radio + * {
          display: flex;
          flex: 1;
          width: 100%;
          padding-right: 0!important;

          .input-container {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .inputs {
              display: flex;

              .ant-input-number {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }

    .button-wrapper {
      justify-content: flex-start!important;
    }

    .date-picker-class {
      width: 100%!important;
    }

    .select-wrapper {
      margin-bottom: 12px;

      .select-dropdown {
        width: 100%!important;
      }
    }
  }

  .label {
    margin: 12px 0;
    opacity: .3;
    color: $label-color;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .6px;
    line-height: 16px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 463px;
    height: 94px;
    margin: 0 auto;

    .action-buttons {
      .button-wrapper + .button-wrapper {
        margin-left: 20px;
      }
    }
  }

  .booking-type {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    &-variable {
      flex-direction: column; 
    }
  }

  .date-and-duration {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }

  .date {
    display: flex;
    flex-direction: column;
  }

  .day {
    text-transform: uppercase;
  }

  .duration {
    display: flex;
    align-items: center;
    gap: .5rem;
  }
}
