@import "src/styles/colors";
@import "src/styles/time-table";

.left-click-menu-container {
  color: #000;
  background-color: #fff;
  width: 350px;
  border-radius: 5px;
  padding: 0px;

  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 10px;

  display: table;
  font-size: 12px;

  @include border-theme-color("left-click-menu-container");

  border: 0;
  border-left: solid 10px;

  .row {
    display: table-row;
  }

  .row > div {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px;
    display: table-cell;
  }

  .row > div:first-child {
    width: 33%;
    color: #979798;
  }

  .row > div.description {
    white-space: pre-line;
  }

  .ant-menu {
    text-align: right;
  }
}
