@import "src/styles/colors";
@import "src/styles/variables";

.app-header-container {
  background-color: $light-gray;
  color: rgba(0, 0, 0, .7);

  .app-header-left-container {

    .app-logo-container {
      display: flex;
      align-items: center;
      padding: $rhythm * .5 $rhythm * 2 $rhythm * .5 $rhythm;

      svg {
        height: 55px;
        margin-left: 1rem;
      }
    }

    .header-link{
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 150px;
      font-size: 12px;
      height: 100%;
      font-weight: 700;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &.active {
        background-color: $white;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .app-header-right-container {
    margin-left: auto;

    .ant-row {
      align-items: center;
      height: 100%;
    }
  }


  .app-header-logout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 25px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    i {
      padding-left: 8px;
    }
  }
}
