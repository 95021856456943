.tag-wrapper {
  display: inline-block;
  height: 30px;
  padding: 0 14px;
  overflow: hidden;
  border-radius: 18px;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  user-select: none;

  &:focus {
    outline: none!important;
  }

  .cancel-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    margin-bottom: -2px;
    margin-left: 8px;
    cursor: pointer;

    &:focus {
      outline: none!important;
    }
  }
}

.invisible {
  display: none;
}
