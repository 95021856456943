$none: none;
$primary: #4a33d9;
$light-gray: #f8f8f9;
$gray: #cecfd2;
$dark-grey: #767676;
$whisper: #f6f7fa;
$white: #ffffff;
$deep-sky-blue: #2d8cf0;
$gainsboro: #d8d8d8;
$eclipse: #3b3b3b;
$white-smoke: #f1f1f1;
$persian-blue: #1e1ece;
$faux-phthalo-blue: #0b0b85;
$nobel: #979798;
$solid-gray: rgba(59, 59, 59, 0.15);
$transparent: transparent;
$black: #000000;
$yellowgreen: yellowgreen;
$light-green: #16ceb9;
$green: #21d37a;
$somegrey: #e1e1e1;
$red: red;
$blue: blue;
$bright-gray: grey;
$app-blue: rgba(34, 185, 246, 0.3);
$label-color: #4a494a;
$free-green: #21d37a;
$tomato: #ff6347;
$mountain-meadow: #30ba8f;
$malachite: #0bda51;
$alizarin: #e32636;
$science-blue: #0153cc;
$resolution-blue: #003685;
$THEME-GREEN-LIGHT: #7ae5af;
$THEME-GREEN-DARK: #21d37a;
$THEME-BLUE-LIGHT: #a7f2ff;
$THEME-BLUE-DARK: #4fe5ff;
$THEME-ORANGE-LIGHT: #ffbd9a;
$THEME-ORANGE-DARK: #ff9c68;
$THEME-RED-LIGHT: #fa97b2;
$THEME-RED-DARK: #f7517f;
$THEME-YELLOW-LIGHT: #fff5aa;
$THEME-YELLOW-DARK: #ffea4f;

$THEME-drupal_tm-LIGHT: #a7f2ff;
$THEME-drupal_tm-DARK: #52e5ff;
$THEME-drupal_fp-LIGHT: #6bcaff;
$THEME-drupal_fp-DARK: #1d96da;
$THEME-drupal_sup-LIGHT: #9ca6ff;
$THEME-drupal_sup-DARK: #6b7aff;
$THEME-cloud_tm-LIGHT: #ffbd9a;
$THEME-cloud_tm-DARK: #ff9c68;
$THEME-cloud_fp-LIGHT: #ffc54e;
$THEME-cloud_fp-DARK: #efaa1b;
$THEME-cloud_sup-LIGHT: #ffe93c;
$THEME-cloud_sup-DARK: #dfcb18;
$THEME-appdev_tm-LIGHT: #7ae5af;
$THEME-appdev_tm-DARK: #21d37a;
$THEME-appdev_fp-LIGHT: #6dfc58;
$THEME-appdev_fp-DARK: #3aea20;
$THEME-appdev_sup-LIGHT: #c5f560;
$THEME-appdev_sup-DARK: #a4e817;
$THEME-ux_ui-LIGHT: #fa97b2;
$THEME-ux_ui-DARK: #f7517f;
$THEME-internal-LIGHT: #d0d0d0;
$THEME-internal-DARK: #aaaaaa;
