.cellBg {
        --_border-color: #DADBDD;
        @apply bg-gray-100;
        background-image: linear-gradient(to right, var(--_border-color) 0%, var(--_border-color) 1px, transparent 1px, transparent calc(100% - 1px), var(--_border-color) calc(100% - 1px), var(--_border-color) 100%);
        background-size: 100% 100%;
}

.cellBg:last-child {
        background-image: linear-gradient(to right, var(--_border-color) 0%, var(--_border-color) 1px, transparent 1px, transparent calc(100% - 2px), var(--_border-color) calc(100% - 2px), var(--_border-color) 100%);
}
