@import "src/styles/colors";
@import "src/styles/variables";

.resource-page-container {
  width: 100%;

  .resource-page-selectors {
    align-items: center;
    margin-left: auto;
    padding: $rhythm;
  }

  .add-new-booking-button {
    margin-left: auto;
    margin-bottom: 0.5rem;
  }
}

.resource-page-selector-label {
  color: $nobel;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;

  &.period {
    padding-left: $rhythm * 0.875;
  }
}

.resource-page-selector-today-button {
  display: flex;
  align-items: center;
  .export-link {
    margin-top: $rhythm * 0.625;
    padding-right: $rhythm * 1.5;
    color: $nobel;
  }
  .button-wrapper {
    margin-top: $rhythm * 0.625;
    padding-left: $rhythm * 1.5;
    padding-right: $rhythm * 1.5;
  }
}
