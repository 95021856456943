@import "src/styles/colors";

.ant-calendar-range-picker-separator,
.ant-calendar-range-middle {
  display: none;
}

.ant-calendar-picker-icon {
  color: unset;
}

.main-class {
  .ant-calendar-picker-input.ant-input {
    border: 1px solid $nobel;
  }
}

.dropdown-class {
  border: 1px solid $nobel;
  border-radius: 4px;

  .ant-calendar-input-wrap {
    display: none;
  }
}

input::placeholder {
  color: $eclipse;
  text-align: left;
}
