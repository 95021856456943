@import "src/styles/colors";

.calendar-event {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.21s ease-out top, 0.21s ease-out left, 0.21s ease-out width;

    background-color: $science-blue;
    border-color: $resolution-blue;
    border-width: 2px;
    border-style: solid;
    cursor: default;
    .content {
        color: white;
        font: 600 9px/10px "Open Sans";
        text-transform: uppercase;
        margin: auto;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
