@import "src/styles/colors";
@import "src/styles/time-table";

.booking-draggable.draggable-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.21s ease-out top, 0.21s ease-out left, 0.21s ease-out width;

    @include bg-theme-color("draggable-content");
    @include border-theme-color("draggable-content");
    border-width: 2px;
    border-style: solid;
    cursor: pointer;

    .content {
        color: black;
        font: 600 9px/10px "Open Sans";
        text-transform: uppercase;
    }

    .side {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 24px;
        cursor: grab;

        &:active {
            cursor: grabbing;
        }

        .side-divider {
            display: block;
            background-color: $eclipse;
            height: 70%;
            width: 1px;
            margin: 0 1px;
        }

        &.expanding {
            opacity: 0;
        }
    }
}
