@import "src/styles/colors";

.login-container {
  @apply min-h-[100vh] min-w-[100vw] flex flex-col justify-center items-center;


  .ant-btn {
    @apply flex justify-center w-[300px] py-2 h-fit border-none text-white uppercase bg-primary hover:bg-primary/90 hover:text-white focus:bg-primary/90 focus:text-white;
  }

  .login-logo {
    display: block;
    width: 300px;
    height: 180px;
    padding: 20px 50px;
    @apply bg-primary/50;
  }
}
