@import "src/styles/colors";

.ant-select-selection {
  border: 1px solid $nobel!important;
  border-radius: 3px!important;
  font-size: 12px;
}

.with-arrow-border {
  .ant-select-selection {
    border-radius: 8px!important;

    .ant-select-selection__rendered {
      margin-right: 34px !important;
      border-right: 1px solid $nobel!important;
    }
  }
}

.curly {
  .ant-select-dropdown-menu.ant-select-dropdown-menu-root.ant-select-dropdown-menu-vertical {
    border-radius: 8px;
  }
}

.ant-select-dropdown-menu-item {
  border-bottom: 1px solid $nobel;
  box-shadow: none!important;
  font-size: 12px!important;
  font-weight: 600!important;
}

.ant-select-dropdown-menu-item-active {
  background-color: $white!important;
}

.ant-select-dropdown-menu-item:last-of-type {
  border-bottom: none;
}

.ant-select-dropdown-menu.ant-select-dropdown-menu-root.ant-select-dropdown-menu-vertical {
  padding: 0;
  border: 1px solid $eclipse;
  border-radius: 3px;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:hover,
.ant-select-selection:active,
.ant-select-open .ant-select-selection {
  border-color: $gainsboro;
  outline: none!important;
  box-shadow: none!important;
}

.ant-select-arrow {
  color: $eclipse!important;
}

.ant-select-selection__rendered {
  border: none!important;
  outline: none!important;
  box-shadow: none!important;
}

.ant-select-dropdown {
  outline: none!important;
  border: 1px solid rgba(68,69,70,0.1);
  box-shadow: 0 2px 6px 0 rgba(34,35,36,0.1);
  border-radius: 2px;
  background-color: $white;
}

.ant-select-item-option {
  border-bottom: 1px solid rgba(68,69,70,0.1);
  padding: 0.5rem 1.25rem;
  color: $nobel;
  text-transform: uppercase;

  &:last-of-type {
    border-bottom: 0;
  }

  &.ant-select-item-option-active {
    font-weight: 700;
    color: $black;
  }
}
