@import "src/styles/colors";

$IMAGE_SIZE: 45px;

.divisiontable {
  background-color: $light-gray;
}

.divisiontable-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray;
  color: $label-color;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 2;
  background: $light-gray;

  .divisiontable-header-profile {
    padding-left: 12px;
    color: $nobel;
  }

  .divisiontable-header-day {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $dark-grey;

    &--today {
      background: $label-color;
      color: $white;
    }
  }
}

.divisiontable-row {
  box-sizing: border-box;
  display: flex;

  .divisiontable-profile {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray;

    .divisiontable-profile-pic-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $IMAGE_SIZE;
      overflow: hidden;
      width: $IMAGE_SIZE;
      margin-left: 12px;
      margin-right: 8px;

      .divisiontable-profile-pic {
        box-sizing: border-box;
        height: $IMAGE_SIZE;
      }
    }

    .divisiontable-profile-name {
      font-size: 14px;
      font-weight: 700;
    }

    .divisiontable-profile-time-label {
      display: inline-block;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      color: $nobel;
      padding-right: 4px;
    }
    .divisiontable-profile-time-value {
      display: inline-block;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 0.3px;
      color: $light-green;
    }
  }

  .divisiontable-row-time {
    display: flex;
    flex: 1 1;
    justify-content: center;
    border-left: 1px solid $gray;
    border-bottom: 1px solid $gray;

    &--last-day-of-week {
      border-right: 1px solid $dark-grey;
    }

    &--today {
      background: $somegrey;
    }

    &--not-available {
      z-index: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      font-weight: 900;
      font-size: 10px;
      text-transform: uppercase;
      background: repeating-linear-gradient(320deg, $light-gray, $light-gray 1px, $somegrey 1px, $somegrey 18px);
    }

    .divisiontable-total-time-per-day {
      position: relative;

      .divisiontable-time-modal {
        display: none;
        width: 300px;
        z-index: 100000;
        background-color: red;
      }

      &:hover .divisiontable-time-modal {
        position: absolute;
        display: unset;
      }
    }
  }
}
