@import "src/styles/colors";

.dropdown-tree-container {
  user-select: none;

  &.non-filterable {
    .search {
      display: none !important;
    }

    .dropdown-content::before {
      display: none;
    }
  }

  .react-dropdown-tree-select {
    height: 19px !important;
    font-size: 12px !important;

    &:focus {
      outline: none;
    }

    .dropdown {
      z-index: 3;
      .dropdown-trigger {
        padding: 0 !important;
        border: none !important;
        color: $eclipse;
        line-height: 24px;

        &.arrow.bottom {
          &:after {
            font-size: 10px;
            color: $nobel;
          }
        }

        &:after {
          padding-left: 8px;
        }

        .tag {
          display: none;
        }

        .tag-item {
          margin: 0 !important;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          color: $nobel;
        }
      }
    }


    .dropdown-content {
      min-width: 250px;
      max-height: 450px;
      padding: 1.25rem;
      overflow: auto;
      border: 1px solid rgba(68,69,70,0.1);
      background-color: #FFFFFF;
      box-shadow: 0 2px 6px 0 rgba(34,35,36,0.1);
      border-radius: 2px;

      .search {
        height: 30px;
        overflow: hidden;
        border: 1px solid #ccc;
        border-radius: 3px;
        caret-color: $primary;
        color: $primary;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0.5rem;
        margin-bottom: 0.5rem;

        &::placeholder {
          color: $nobel;
          text-transform: uppercase;
          font-weight: 700;
        }
      }

      .infinite-scroll-component {
        .toggle.expanded {
          display: none;
        }

        .node.leaf[aria-level="1"],
        .node.tree[aria-level="1"] {
          padding-left: 4px !important;
        }

        .node.tree,
        .node.leaf {
          &:hover {
            .node-label {
              color: $black;

              &::before {
                width: 18px;
                height: 18px;
                margin-right: 8px;
                border: 2px solid $primary;
                border-radius: 3px;
              }
            }
          }

          &.focused {
            background-color: transparent;
          }
        }

        li:last-of-type {
          border-bottom: none !important;
        }

        input[type="checkbox"] {
          display: none;
        }

        .node-label {
          display: flex;
          align-items: center;
          user-select: none;
          text-transform: uppercase;
          font-weight: 700;
          color: $nobel;
          position: relative;
        }

        .node-label::before {
          content: "";
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-right: 8px;
          border: 2px solid $primary;
          border-radius: 3px;
        }

        input[type="checkbox"]:checked + .node-label::before {
          background-color: $primary;
        }

        input[type="checkbox"]:checked + .node-label {
          color: $black;
          background-color: transparent;
        }

        input[type="checkbox"]:checked + .node-label::after {
          position: absolute;
          display: table;
          color: #fff;
          border-top: 0;
          border-left: 0;
          opacity: 1;
          transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
          content: '✓';
          left: 3px;
          /* stylelint-disable */
          //background: url("../../assets/images/white-check.jpg") !important;
          /* stylelint-enable */
        }

        li:hover {
          .node-label::before {
            content: "";
            width: 15px;
            height: 15px;
            margin-right: 4px;
            border: 1px solid $persian-blue;
            border-radius: 3px;
            background: $transparent;
          }
        }

        label {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

/* eslint-enable */
