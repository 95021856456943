@mixin border-theme-color($class) {
    &.#{$class}-theme-default,
    &.#{$class}-theme-blue {
        border-color: $THEME-BLUE-DARK;
    }

    &.#{$class}-theme-orange {
        border-color: $THEME-ORANGE-DARK;
    }

    &.#{$class}-theme-green {
        border-color: $THEME-GREEN-DARK;
    }
    &.#{$class}-theme-red {
        border-color: $THEME-RED-DARK;
    }
    &.#{$class}-theme-yellow {
        border-color: $THEME-YELLOW-DARK;
    }
    &.#{$class}-theme-drupal_tm {
        border-color: $THEME-drupal_tm-DARK;
    }
    &.#{$class}-theme-drupal_fp {
        border-color: $THEME-drupal_fp-DARK;
    }
    &.#{$class}-theme-drupal_sup {
        border-color: $THEME-drupal_sup-DARK;
    }
    &.#{$class}-theme-cloud_tm {
        border-color: $THEME-cloud_tm-DARK;
    }
    &.#{$class}-theme-cloud_fp {
        border-color: $THEME-cloud_fp-DARK;
    }
    &.#{$class}-theme-cloud_sup {
        border-color: $THEME-cloud_sup-DARK;
    }
    &.#{$class}-theme-appdev_tm {
        border-color: $THEME-appdev_tm-DARK;
    }
    &.#{$class}-theme-appdev_fp {
        border-color: $THEME-appdev_fp-DARK;
    }
    &.#{$class}-theme-appdev_sup {
        border-color: $THEME-appdev_sup-DARK;
    }
    &.#{$class}-theme-ux_ui {
        border-color: $THEME-ux_ui-DARK;
    }
    &.#{$class}-theme-internal {
        border-color: $THEME-internal-DARK;
    }
}

@mixin bg-theme-color($class) {
    &.#{$class}-theme-default,
    &.#{$class}-theme-blue {
        background-color: $THEME-BLUE-LIGHT;
    }

    &.#{$class}-theme-orange {
        background-color: $THEME-ORANGE-LIGHT;
    }

    &.#{$class}-theme-green {
        background-color: $THEME-GREEN-LIGHT;
    }
    &.#{$class}-theme-red {
        background-color: $THEME-RED-LIGHT;
    }
    &.#{$class}-theme-yellow {
        background-color: $THEME-YELLOW-LIGHT;
    }
    &.#{$class}-theme-drupal_tm {
        background-color: $THEME-drupal_tm-LIGHT;
    }
    &.#{$class}-theme-drupal_fp {
        background-color: $THEME-drupal_fp-LIGHT;
    }
    &.#{$class}-theme-drupal_sup {
        background-color: $THEME-drupal_sup-LIGHT;
    }
    &.#{$class}-theme-cloud_tm {
        background-color: $THEME-cloud_tm-LIGHT;
    }
    &.#{$class}-theme-cloud_fp {
        background-color: $THEME-cloud_fp-LIGHT;
    }
    &.#{$class}-theme-cloud_sup {
        background-color: $THEME-cloud_sup-LIGHT;
    }
    &.#{$class}-theme-appdev_tm {
        background-color: $THEME-appdev_tm-LIGHT;
    }
    &.#{$class}-theme-appdev_fp {
        background-color: $THEME-appdev_fp-LIGHT;
    }
    &.#{$class}-theme-appdev_sup {
        background-color: $THEME-appdev_sup-LIGHT;
    }
    &.#{$class}-theme-ux_ui {
        background-color: $THEME-ux_ui-LIGHT;
    }
    &.#{$class}-theme-internal {
        background-color: $THEME-internal-LIGHT;
    }

    @each $color in COLORS {
        &.#{$class}-theme-#{$color} {
            background-color: $color;
        }
    }
}
