@import "src/styles/colors";

.ant-checkbox-inner {
  width: 22px;
  height: 22px;
  @apply border-2 border-primary
}

.ant-checkbox-wrapper {
  text-transform: uppercase;
  @apply text-gray-400;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;

  .ant-checkbox + span {
    padding-top: 5px;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  @apply border-2 border-primary bg-primary
}

.ant-radio-inner {
  @apply border-primary
}

.ant-radio-checked .ant-radio-inner {
  @apply border-primary
}

.ant-radio-inner:after {
 @apply bg-primary
}

.ant-radio-input:focus+.ant-radio-inner {
  @apply shadow shadow-primary
}

.ant-radio-input:focus+.ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner {
  @apply border-primary
}

.ant-checkbox-checked:after, .ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  @apply border-primary
}
