@import "src/styles/colors";
@import "src/styles/variables";

.filter-section {
  background-color: $white;

  .filter-title {
    font-weight: 700;
    text-transform: uppercase;
    color: $nobel;
  }

  .filter-controller-container {
    padding: $rhythm;
    flex-wrap: wrap;

    .filter-controller-item {
      margin-right: $rhythm;
      display: flex;
      align-items: center;
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      @apply border-2 border-primary;
    }

    .ant-checkbox-wrapper {
      text-transform: uppercase;
      color: $nobel;
      font-weight: 700;
      font-size: 12px;
      display: flex;
      align-items: center;

      .ant-checkbox + span {
        padding-top: 5px;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border: 2px solid $primary;
      background-color: $primary;
    }
  }
}

.no-filter-tag-container {
  height: 0;
  overflow: hidden;
  // transition: height .6s ease;
}

.filter-tag-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  // min-height: 40px;
  // position: absolute;
  // top: 75px; // Filter section height
  // padding-left: $rhythm;
  // transition: height 1s ease;

  .filter-tag-invisible {
    margin: 0;
  }
}

.tag-wrapper {
  background-color: $primary !important;
  height: 24px;
  line-height: 24px;
  border-radius: 8px;
  padding: 0 $rhythm * .5 0 $rhythm * .875;
  margin-right: $rhythm / 2;

  .cancel-tag {
    margin-bottom: 0;

    svg {
      height: 10px;
    }
  }
}

