.button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  overflow: hidden;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: .4px;
  line-height: 12px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &.disabled {
    opacity: .3;
    cursor: not-allowed;
  }

  &:focus {
    outline: none!important;
  }
  
  &.lg {
    height: 35px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 35px;
  }
  
  .ant-spin {
    display: inline-block;
    padding-right: 12px;
  }
}
