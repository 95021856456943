@import "src/styles/colors";

.date-setter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 30px;
  border: 0;
  border-radius: 8px;
  font-size: 11px;
  letter-spacing: normal;

  .date-setter-button {
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    outline: inherit;
    background: $none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    border: 0;
  }

  .date-setter-date {
    text-transform: uppercase;
    font-size: 12px;
    color: $label-color;
    font-weight: 700;
  }
}
