@import "src/styles/colors";

.timetable {
  width: 100%;
}

.ant-tooltip-inner {
  color: #000;
  background-color: #fff;
  width: 350px;
  border-radius: 5px;
  padding: 0px;
  font-size: 12px;
}

.ant-tooltip-inner > div {
  display: table;
  width: 100%;
  border-bottom: solid 2px #ececec;
  padding: 0px;

  .time {
    display: table-cell;
    color: #5dddce;
    font-weight: bold;
    padding: 10px;
    width: 80px;
    text-align: center;
  }

  .project {
    display: table-cell;
    color: #000;
    font-weight: bold;
    padding: 10px;
    text-transform: uppercase;
    .description {
      text-transform: none;
      white-space: pre-line;
      color: $dark-grey;
    }
  }
}

.ant-tooltip-inner > div:last-child {
  border-bottom: 0px;
}

.ant-tooltip-arrow-content {
  background-color: transparent !important;
  --antd-arrow-background-color: transparent;
}

.divisiontable-total-time-per-day > span {
  font-weight: bold;
}
